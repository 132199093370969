import { default as ca } from '../../assets/i18n/ca.json';
import { default as es } from '../../assets/i18n/es.json';
import { default as en } from '../../assets/i18n/en.json';
import * as Localization from 'expo-localization';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

interface LanguageDescription {
	name: Languages;
	label: string;
}

export enum Languages {
	ca = 'ca',
	es = 'es',
	en = 'en',
}

export const AvailableLanguages: LanguageDescription[] = [
	{ name: Languages.ca, label: 'Català' },
	{ name: Languages.es, label: 'Castellano' },
	{ name: Languages.en, label: 'English' },
];

export const getCurrentLanguage = (): Languages => {
	return AvailableLanguages.find((x) => x.name === i18n.language).name;
};

export const getCurrentLanguageIndex = (): number => {
	return AvailableLanguages.findIndex((x) => x.name === i18n.language);
};

export const changeLanguageByIndex = (index: number): void => {
	i18n.changeLanguage(AvailableLanguages[index].name);
	console.log(i18n);
};

const resources = {
	ca: {
		translation: ca,
	},
	es: {
		translation: es,
	},
	en: {
		translation: en,
	},
};

i18n.use(initReactI18next).init({
	resources,
	fallbackLng: Languages.en,
	lng: Localization.locale,
});

export default i18n;
