import { createStackNavigator } from '@react-navigation/stack';
import { LoginScreen } from '../screens/core/login/Login';
import LanguageDropdownHeader from '../components/shared/header/LanguageDropdownHeader';

const { Navigator, Screen } = createStackNavigator();

const AuthNavigator = (): React.ReactElement => {
	return (
		<Navigator
			screenOptions={{
				title: 'Escaleta',
				headerStyle: { backgroundColor: 'blue', height: '40px' },
				headerShown: true,
				headerRight: LanguageDropdownHeader,
			}}
			initialRouteName="Login"
		>
			<Screen name="Login" component={LoginScreen} />
		</Navigator>
	);
};

export default AuthNavigator;
