import {
	ActionReducerMapBuilder,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { client } from '../../../services/BackendClientService';
import { LoginData } from './LoginData';
import { LoginResponse } from './LoginResponse';
import AsyncStorage from '@react-native-async-storage/async-storage';

type AuthStatus = 'loggedOut' | 'loggedIn' | 'loggingIn';
const AUTH_STORAGE_KEY: string = 'AUTH_STORAGE_KEY';

export interface AuthState {
	status: AuthStatus;
	data: LoginResponse | undefined;
}

const initialState: AuthState = {
	status: 'loggedOut',
	data: undefined,
};

export const loginThunk = createAsyncThunk(
	'auth/login',
	async (loginData: LoginData): Promise<LoginResponse> => {
		const loginResponse: LoginResponse = await client.post(
			'auth/login',
			loginData
		);
		await AsyncStorage.setItem(
			AUTH_STORAGE_KEY,
			JSON.stringify(loginResponse)
		);
		return loginResponse;
	}
);

export const checkIfStillLoggedIn = createAsyncThunk(
	'checkIfStillLoggedIn',
	async (): Promise<LoginResponse | undefined> => {
		console.log('aaaa');
		const rawAuth: string | null = await AsyncStorage.getItem(
			AUTH_STORAGE_KEY
		);
		if (rawAuth === null) {
			return undefined;
		} else {
			return JSON.parse(rawAuth);
		}
	}
);

export const AuthSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
		builder
			.addCase(loginThunk.pending, (state, action) => {
				state.status = 'loggingIn';
				state.data = undefined;
			})
			.addCase(loginThunk.fulfilled, (state, action) => {
				return { ...state, status: 'loggedIn', data: action.payload };
			})
			.addCase(checkIfStillLoggedIn.fulfilled, (state, action) => {
				return {
					...state,
					status:
						action.payload === undefined ? 'loggedOut' : 'loggedIn',
					data: action.payload,
				};
			});
	},
});

export const loggedIn = (state) =>
	state.auth.data !== undefined && state.auth.data.access_token !== '';

export default AuthSlice.reducer;
