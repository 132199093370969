import { createSlice } from '@reduxjs/toolkit';
import { Time } from '../../components/shared/TimeInput';

export interface EscaletaLineState {
	timeLapse: Time;
	description: string;
	position: string;
}

export interface EscaletaState {
	result: string;
	startingHour: Time;
	escaletaLines: EscaletaLineState[];
}

const emptyEscaletaLine: EscaletaLineState = {
	timeLapse: { hours: 0, minutes: 20, separator: ':' },
	description: 'Visita institucional',
	position: 'Xavier Trias',
};

const initialState: EscaletaState = {
	result: '',
	startingHour: { hours: 12, minutes: 30, separator: ':' },
	escaletaLines: [emptyEscaletaLine],
};

export const EscaletaSlice = createSlice({
	name: 'escaleta',
	initialState,
	reducers: {
		setResult: (state, newResult) => {
			state.result = newResult.payload;
		},
		addNewLine: (state) => {
			state.escaletaLines.push(emptyEscaletaLine);
		},
		setStartingHour: (state, newStartingHour) => {
			state.startingHour = newStartingHour.payload.newStartingHour;
		},
		setTimeLapse: (state, newTimeLapse) => {
			state.escaletaLines[newTimeLapse.payload.index].timeLapse =
				newTimeLapse.payload.newTimeLapse;
		},
		setDescription: (state, newDescription) => {
			state.escaletaLines[newDescription.payload.index].description =
				newDescription.payload.newDescription;
		},
		setPosition: (state, newPosition) => {
			state.escaletaLines[newPosition.payload.index].position =
				newPosition.payload.newPosition;
		},
		setEscaletaState: (state, newState) => {
			state.startingHour = newState.payload.newState.startingHour;
			state.escaletaLines = newState.payload.newState.escaletaLines;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	setResult,
	addNewLine,
	setStartingHour,
	setTimeLapse,
	setDescription,
	setPosition,
	setEscaletaState,
} = EscaletaSlice.actions;

export default EscaletaSlice.reducer;
