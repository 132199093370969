import { Input, InputProps, Layout, Text } from '@ui-kitten/components';

export interface InputFormProps extends InputProps {
	label: string;
}

export const InputForm: React.FC<InputFormProps> = ({
	label,
	...restInputProps
}) => {
	return (
		<Layout>
			<Text>{label}</Text>
			<Input {...restInputProps} />
		</Layout>
	);
};
