// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

export async function client(
	endpoint: RequestInfo,
	{ body, ...customConfig }: RequestInit = {}
) {
	const headers = { 'Content-Type': 'application/json' };

	const config: RequestInit = {
		method: body ? 'POST' : 'GET',
		...customConfig,
		headers: {
			...headers,
			...customConfig.headers,
		},
	};

	if (body) {
		config.body = JSON.stringify(body);
	}
	endpoint = `${process.env.EXPO_PUBLIC_API_URL}/${endpoint}`;
	let data;
	try {
		const response = await window.fetch(endpoint, config);
		data = await response.json();
		if (response.ok) {
			return data;
		}
		throw new Error(response.statusText);
	} catch (err) {
		return Promise.reject(err.message ? err.message : data);
	}
}

client.get = function (endpoint: RequestInfo, customConfig = {}) {
	return client(endpoint, { ...customConfig, method: 'GET' });
};

client.post = function (endpoint: RequestInfo, body, customConfig = {}) {
	return client(endpoint, { ...customConfig, body });
};
