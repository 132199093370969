import { Layout } from '@ui-kitten/components';
import { SafeAreaView, StyleSheet } from 'react-native';

const Screen = ({ children }) => {
	return (
		<SafeAreaView style={{ flex: 1 }}>
			<Layout style={styles.container}>{children}</Layout>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		paddingHorizontal: '20px',
	},
});

export default Screen;
