import { Button, Input, Layout, Text } from '@ui-kitten/components';
import { StatusBar } from 'expo-status-bar';
import { SafeAreaView, StyleSheet } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../hooks/StoreHooks';
import {
	EscaletaLineState,
	setResult,
	addNewLine,
	setDescription,
	setTimeLapse,
	setStartingHour,
	setPosition,
	EscaletaState,
	setEscaletaState,
} from '../../redux/store/EscaletaSlice';
import TimePicker from 'react-time-picker';
import { InputForm } from '../../components/shared/forms/InputForm';
import {
	Time,
	TimeInput,
	timeToString,
} from '../../components/shared/TimeInput';
import { useEffect, useState } from 'react';
import { TimeInputForm } from '../../components/shared/forms/TimeInputForm';
import YAML from 'yaml';

export const HomeScreen = () => {
	const result: string = useAppSelector((state) => state.escaleta.result);
	const escaletaLines: EscaletaLineState[] = useAppSelector(
		(state) => state.escaleta.escaletaLines
	);
	const startingHour: Time = useAppSelector(
		(state) => state.escaleta.startingHour
	);
	const escaleta: EscaletaState = useAppSelector((state) => state.escaleta);
	const dispatch = useAppDispatch();

	const generateEscaleta = () => {
		let newResult: string = '';
		let lastStartingHour: Date = startingHour
			? new Date('0001-01-01T' + timeToString(startingHour))
			: undefined;
		escaletaLines.forEach((line: EscaletaLineState) => {
			const endingHour = new Date(lastStartingHour);
			endingHour.setTime(
				lastStartingHour.getTime() +
					1000 *
						(line.timeLapse.hours * 60 * 60 +
							line.timeLapse.minutes * 60)
			);
			newResult += `${lastStartingHour.getHours()}:${lastStartingHour.getMinutes()} ${endingHour.getHours()}:${endingHour.getMinutes()} - ${
				line.description
			}\n\t\t\t${line.position}\n`;
			lastStartingHour = endingHour;
		});
		dispatch(setResult(newResult));
	};
	const addNewLineEscaleta = () => {
		dispatch(addNewLine());
	};
	const changeStartingHour = (newStartingHour: Time) => {
		dispatch(
			setStartingHour({
				newStartingHour,
			})
		);
	};
	const changeTimeLapse = (index: number, newTimeLapse: Time) => {
		dispatch(setTimeLapse({ index, newTimeLapse }));
	};
	const changeDescription = (index: number, newDescription: string) => {
		dispatch(setDescription({ index, newDescription }));
	};
	const changePosition = (index: number, newPosition: string) => {
		dispatch(setPosition({ index, newPosition }));
	};
	const exportYaml = () => {
		const yaml: string = YAML.stringify(escaleta);
		const url = window.URL.createObjectURL(
			new Blob([yaml], {
				type: 'text/yaml',
			})
		);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'export.yaml');
		document.body.appendChild(link);
		link.click();
		link.remove();
	};
	const fileOnChange = async (e): Promise<void> => {
		const file: File = e.target.files[0];
		const escaleta: EscaletaState = YAML.parse(await file.text());
		dispatch(setEscaletaState({ newState: escaleta }));
		e.target.value = null;
	};

	useEffect(() => {
		generateEscaleta();
	}, [startingHour, escaletaLines, escaleta]);
	return (
		<SafeAreaView style={{ flex: 1 }}>
			<Layout style={styles.container}>
				<Layout style={styles.section}>
					<Layout>
						<Text category="h1">Planifica l'escaleta</Text>
						<input type="file" onChange={fileOnChange} />
						<Button>Importa Escaleta</Button>
					</Layout>

					<TimeInputForm
						label="Hora començament"
						time={startingHour}
						onChangeTime={(time: Time) => changeStartingHour(time)}
					/>
					{escaletaLines.map(
						(line: EscaletaLineState, index: number) => (
							<Layout style={styles.inputLine} key={index}>
								<TimeInputForm
									label="Duració"
									time={line.timeLapse}
									onChangeTime={(time: Time) =>
										changeTimeLapse(index, time)
									}
								/>
								<InputForm
									label="Descripció"
									placeholder="Introdueixi una descripció"
									value={line.description}
									multiline={true}
									numberOfLines={3}
									onChangeText={(text: string) =>
										changeDescription(index, text)
									}
								/>
								<InputForm
									label="Càrrec"
									placeholder="Introdueixi el càrrec"
									value={line.position}
									multiline={true}
									numberOfLines={3}
									onChangeText={(text: string) =>
										changePosition(index, text)
									}
								/>
							</Layout>
						)
					)}
					<Layout>
						<Button onPress={addNewLineEscaleta}>Afegeix</Button>
					</Layout>
					<Layout>
						<Button onPress={exportYaml}>Exporta escaleta</Button>
					</Layout>
				</Layout>
				<Layout style={styles.section}>
					<Input
						placeholder="Sortida"
						value={result}
						multiline={true}
						numberOfLines={5}
					/>
				</Layout>
			</Layout>
		</SafeAreaView>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		paddingHorizontal: '20px',
	},
	section: { minWidth: '500px' },
	inputLine: {
		flex: 1,
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
});
