import { Button, Icon, Layout, Text } from '@ui-kitten/components';
import { StyleSheet } from 'react-native';
import { InputForm } from '../../../components/shared/forms/InputForm';
import { useState } from 'react';
import { useAppDispatch } from '../../../hooks/StoreHooks';
import { loginThunk } from '../../../redux/store/Auth/AuthSlice';
import Screen from '../../../components/shared/Screen';
import { useTranslation } from 'react-i18next';

export const LoginScreen = () => {
	const [email, setEmail] = useState<string | undefined>();
	const [password, setPassword] = useState<string | undefined>();
	// const [secureTextEntry, setSecureTextEntry] = useState<boolean>(true);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	// const toggleSecureEntry = (): void => {
	// 	console.log(secureTextEntry);
	// 	setSecureTextEntry(!secureTextEntry);
	// };

	// const renderIcon = (props): React.ReactElement => (
	// 	<TouchableWithoutFeedback onPress={toggleSecureEntry}>
	// 		<Icon {...props} name={secureTextEntry ? 'eye-off' : 'eye'} />
	// 	</TouchableWithoutFeedback>
	// );

	const login = () => {
		dispatch(loginThunk({ email, password }));
	};

	return (
		<Screen>
			<Layout style={styles.section}>
				<Text category="h1">{t('Login.Entrar')}</Text>
				<InputForm
					label={t('Login.Correu electrònic')}
					placeholder={t('Login.Introdueixi el correu electrònic')}
					value={email}
					onChangeText={(text: string) => setEmail(text)}
				/>
				<InputForm
					label={t('Login.Contrasenya')}
					placeholder={t('Login.Introdueixi la contrasenya')}
					value={password}
					secureTextEntry={true}
					// accessoryRight={renderIcon}
					onChangeText={(text: string) => setPassword(text)}
				/>
				<Button onPress={login}>{t('Login.Entrar')}</Button>
			</Layout>
		</Screen>
	);
};

const styles = StyleSheet.create({
	section: { minWidth: '500px' },
});
