import * as eva from '@eva-design/eva';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import * as theme from './assets/theme/theme.json';
import * as mapping from './assets/fonts/mapping.json';
import { AppNavigator } from './src/navigation/AppNavigator';
import { Provider } from 'react-redux';
import AppStore from './src/redux/store/AppStore';
import React from 'react';
import { FeatherIconsPack } from './src/config/FeatherIconsPack';

export default function App() {
	const [currentTheme, setCurrentTheme] = React.useState({
		...eva.dark,
		...theme,
	});
	return (
		<>
			<IconRegistry icons={FeatherIconsPack} />
			<ApplicationProvider
				{...eva}
				theme={currentTheme}
				customMapping={mapping}
			>
				<Provider store={AppStore}>
					<AppNavigator />
				</Provider>
			</ApplicationProvider>
		</>
	);
}
