import {
	IndexPath,
	Layout,
	Select,
	SelectItem,
	Text,
} from '@ui-kitten/components';
import { useState } from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import {
	AvailableLanguages,
	changeLanguageByIndex,
	getCurrentLanguageIndex,
} from '../../../services/I18n';

const LanguageDropdownHeader = (): React.ReactElement => {
	const [selectedLanguage, setSelectedLanguage] = useState<IndexPath>(
		new IndexPath(getCurrentLanguageIndex())
	);

	const onLanguageChanged = (index: IndexPath): void => {
		changeLanguageByIndex(index.row);
		setSelectedLanguage(index);
	};

	return (
		<Layout style={styles.container}>
			<Select
				selectedIndex={selectedLanguage}
				onSelect={onLanguageChanged}
				value={AvailableLanguages[selectedLanguage.row].label}
				style={styles.select}
			>
				{AvailableLanguages.map((language) => (
					<SelectItem title={language.label} key={language.name} />
				))}
			</Select>
		</Layout>
	);
};
const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	select: {
		flex: 1,
		margin: 1, // if not it doesn't have direction vertical
	},
});

export default LanguageDropdownHeader;
