import { Layout, Text } from '@ui-kitten/components';
import { TimeInput, TimeInputProps } from '../TimeInput';

export interface TimeInputFormProps extends TimeInputProps {
	label: string;
}

export const TimeInputForm: React.FC<TimeInputFormProps> = ({
	label,
	...restTimeInputProps
}) => {
	return (
		<Layout>
			<Text>{label}</Text>
			<TimeInput {...restTimeInputProps} />
		</Layout>
	);
};
