import React, { useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { HomeScreen } from '../screens/home/Home';
import AuthNavigator from './AuthNavigator';
import { useSelector } from 'react-redux';
import { checkIfStillLoggedIn, loggedIn } from '../redux/store/Auth/AuthSlice';
import { useAppDispatch } from '../hooks/StoreHooks';

const { Navigator, Screen } = createStackNavigator();

const HomeNavigator = (): React.ReactElement => {
	return (
		<Navigator
			screenOptions={{
				title: 'Escaleta',
				headerStyle: { backgroundColor: 'grey' },
				headerShown: true,
			}}
			initialRouteName="Home"
		>
			<Screen name="Home" component={HomeScreen} />
			{/* <Screen name='Details' component={DetailsScreen}/> */}
		</Navigator>
	);
};

export const AppNavigator = (): React.ReactElement => {
	const [checkedLoggedIn, setCheckedLoggedIn] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const isLoggedIn: boolean = useSelector(loggedIn);

	if (!checkedLoggedIn) {
		dispatch(checkIfStillLoggedIn());
		setCheckedLoggedIn(true);
	}
	return (
		<NavigationContainer>
			{isLoggedIn && <HomeNavigator />}
			{!isLoggedIn && <AuthNavigator />}
		</NavigationContainer>
	);
};
