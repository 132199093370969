import { Input, InputProps } from '@ui-kitten/components';
import { useEffect, useState } from 'react';
import { NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';

export interface TimeInputProps extends InputProps {
	time: Time;
	onChangeTime: (changedTime: Time) => void;
}

export class Time {
	hours: number = 0;
	minutes: number = 0;
	separator: string = ':';

	constructor(hours: number, minutes: number) {
		this.hours = hours;
		this.minutes = minutes;
	}
}

export function timeToString(time: Time): string {
	return `${time.hours}${time.separator}${time.minutes}`;
}

export function stringToTime(value: string, separator: string): Time {
	const [hours, minutes] = value.split(separator);
	return { separator, hours: +hours, minutes: +minutes };
}

export const TimeInput: React.FC<TimeInputProps> = ({
	time,
	onChangeTime,
	...restTimeInputProps
}) => {
	const [workingTime, setWorkingTime] = useState<string>('');
	const changeText = (value: string) => {
		setWorkingTime(value);
		onChangeTime(stringToTime(value, time.separator));
	};

	useEffect(() => {
		setWorkingTime(timeToString(time));
	}, [time]);

	return (
		<Input
			value={workingTime}
			{...restTimeInputProps}
			onChangeText={(e) => changeText(e)}
		/>
	);
};
